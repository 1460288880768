// default config for all the environments
const defaultConfig = {
  language: "en",
};
 
// write here every environmet configuration
const modes = {
  development: {
    ...defaultConfig,
    // API_URL: "https://app-api-prd.gero3d.com/api/v1/admin", // prd
    API_URL: "https://app-api-dev.gero3d.com/api/v1/admin", // dev
    // API_URL: "http://localhost:3000/api/v1/admin" // local
  },
  // staging: {
  //   ...defaultConfig,
  //   API_URL: "TODO: config",
  // },
  // production: {
  //   ...defaultConfig,
  //   API_URL: "TODO: config",
  // },
};

const appMode = modes[process.env.REACT_APP_ENV] || modes.development;

export default appMode;

export const defaultTimezone = "Etc/UTC";