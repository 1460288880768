import React from 'react';
import FractureLocationFilter from './filter';
import Breadcrumbs from 'components/Breadcrumbs';
import DataGridList from 'components/containers/List';
import EmptyPage from 'components/Page/Empty/EmptyPage';
import { Paginator } from 'components/Paginator/Paginator';
import ListActions from 'components/ListAction/ListActions';
import { BooleanField, List, TextField } from 'react-admin';

export const FractureLocationsList = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <List
      title='Fracture Locations'
      filters={<FractureLocationFilter />}
      pagination={<Paginator />}
      perPage={15}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
      empty={<EmptyPage create />}
      actions={<ListActions listExporterType='Fracture Locations' />}
    >
      <DataGridList edit show>
        <TextField source='location' label="Location" />
        <BooleanField source='is_available' label="Is Available?" />

      </DataGridList>
      
    </List>
    </>
    )
};