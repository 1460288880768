import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';

import { TrainingsList } from './list';
import { TrainingsEdit } from './edit';
import { TrainingsShow } from './show';
import { TrainingCreate } from './create';

export default {
  name: "trainings",
  options: { label: "Trainings", menuParent: "_trainings" },
  icon: OndemandVideoIcon,
  list: TrainingsList,
  show: TrainingsShow,
  create: TrainingCreate,
  edit: TrainingsEdit,
};
