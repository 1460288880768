import React from 'react';
import OrderFilter from './filter';
import Breadcrumbs from 'components/Breadcrumbs';
import DataGridList from 'components/containers/List';
import EmptyPage from 'components/Page/Empty/EmptyPage';
import { Paginator } from 'components/Paginator/Paginator';
import ListActions from 'components/ListAction/ListActions';
import { FormattedTextField } from 'components/Fields/FormattedTextField';
import { List, TextField, ReferenceField, FunctionField } from 'react-admin';

const formatDate = (dateString, timeZone = 'UTC') => {
  const date = new Date(dateString);
  return date.toLocaleString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: timeZone
  }).replace(',', '');
};

export const OrdersList = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <List
      title='Orders'
      filters={<OrderFilter />}
      pagination={<Paginator />}
      perPage={15}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
      empty={<EmptyPage />}
      actions={<ListActions listExporterType='Orders' hideCreateButton={true} />}
    >
      <DataGridList edit show disableDates hideID>
        <TextField source='id' label="Order Number" />
        <TextField source='full_name' label="Patient Name" />
        <TextField source='therapist_name' label="Therapist Name" />
        
        <ReferenceField 
          source="clinic_id"
          reference="clinics"
          label="Clinic Name"
          link={(record, reference) =>
            `/${reference}/${record.clinic_id}/show`
          }
        >
          <FunctionField render={(record) => `${record.name}`} />
        </ReferenceField>

        <FunctionField
          source='created_at'
          label="Order Date"
          render={record => formatDate(record.created_at, 'Asia/Jerusalem')}
        />


        <FormattedTextField source='order_status' label="Order Status" />

        <FunctionField
          source='status_change_date_time'
          label="Status Updated"
          render={record => formatDate(record.status_change_date_time, 'Asia/Jerusalem')}
        />

        <ReferenceField 
          source="fracture_location_id"
          reference="fracture_locations"
          label="Fracture Location"
          link={(record, reference) =>
            `/${reference}/${record.fracture_location_id}/show`
          }
        >
          <FunctionField render={(record) => `${record.location}`} />
        </ReferenceField>

        <TextField source='limb' label="Scanned Limb" />

        <ReferenceField 
          source="cast_type_id"
          reference="cast_types"
          label="Cast Type"
          link={(record, reference) =>
            `/${reference}/${record.cast_type_id}/show`
          }
        >
          <FunctionField render={(record) => `${record.name}`} />
        </ReferenceField>

      </DataGridList>
      
    </List>
    </>
    )
};