import React from "react";
import { EditTitle } from "../../../components/AppBar/Titles";
import { validateUserPassword } from '../helpers/validations/main';
import { EditToolbar } from "../../../components/Toolbar/EditToolbar";
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  ReferenceInput,
  SelectInput
} from "react-admin";
import Breadcrumbs from "components/Breadcrumbs";

export const UsersEdit = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <Edit title={<EditTitle source="name" />} {...props}>
      <SimpleForm variant="standard" margin="normal" toolbar={<EditToolbar />}>
      <TextInput source="therapist_name" label="Therapist Name" validate={required()} fullWidth />
      <TextInput source="email" label="Email" disabled fullWidth />
      
      <TextInput 
        source="new_email" 
        label="New Email" 
        fullWidth 
        helperText="Optional. Only fill if updating." 
      />

      <TextInput 
        source="new_password" 
        label="New Password" 
        fullWidth 
        helperText="Optional. Only fill if updating."
        validate={[validateUserPassword]}
      />

      <ReferenceInput
        source="clinic_id"
        reference="clinics"
        label="Clinic"
        perPage={500}
        validate={required()}
        
      >
         <SelectInput optionText={(record) => `${record.name} (${record.id})`} />
      </ReferenceInput>
      </SimpleForm>
    </Edit>
    </>
  );
}