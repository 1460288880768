import React from 'react';
import { useRecordContext, Labeled } from 'react-admin';

export const VideoInput = ({ record }) => {
  const videoUrl = record.src;

  return (
    videoUrl ? <video controls width="250">
      <source src={videoUrl} type="video/mp4" />
      Your browser does not support the video tag.
    </video> : <></>
  );
};

export const VideoField = ({ source, label, ...props }) => {
  let recordContext = useRecordContext();
  let record = props.record ?? recordContext;

  if (!record) return null;
  
  let nestedSource = source.split('.');

  let videoUrl;
  for (const key of nestedSource) {
    record = record?.[key];
    videoUrl = record;
  }

  return (
      videoUrl ? <Labeled label={label}>
        <video controls width="250">
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Labeled> : <></>
  );
};
