import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import { makeStyles } from '@material-ui/core/styles';
import { ViewTitle } from "../../../components/AppBar/Titles";
import { 
  Show,
  ImageField,
  FunctionField,
  ReferenceField,
  SimpleShowLayout,
} from "react-admin";

const useStyles = makeStyles({
  customImage: {
    '& img': {
      width: '200px',
      height: '200px',
    }
  },
});

export const CastTypePatternColorMappingShow = (props) => {
  const classes = useStyles();
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent}/>
      <Show title={<ViewTitle source="name" />} {...props}>
      <SimpleShowLayout>

          <ReferenceField ReferenceField 
            source="type_id"
            reference="cast_types"
            label="Cast Type"
            link={(record, reference) =>
              `/${reference}/${record.type_id}/show`
            }
          >
            <FunctionField render={(record) => `${record.name}`} />
          </ReferenceField>

          <ReferenceField ReferenceField 
            source="pattern_id"
            reference="cast_patterns"
            label="Cast Pattern"
            link={(record, reference) =>
              `/${reference}/${record.pattern_id}/show`
            }
          >
            <FunctionField render={(record) => `${record.name}`} />
          </ReferenceField>
          
          <ReferenceField ReferenceField 
            source="color_id"
            reference="cast_colors"
            label="Cast Color"
            link={(record, reference) =>
              `/${reference}/${record.color_id}/show`
            }
          >
            <FunctionField render={(record) => `${record.name}`} />
          </ReferenceField>

          <ImageField source="thumbnail.url" label="Thumbnail" sortable={false} className={classes.customImage} />

        </SimpleShowLayout>
      </Show>
  </>
);
}