import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import { VideoInput } from "components/Video/VideoComponents";
import { EditTitle } from "../../../components/AppBar/Titles";
import { EditToolbar } from "../../../components/Toolbar/EditToolbar";
import { FileUploaderPlaceholder } from "components/FileUploaderPlaceholder";
import {
  Edit,
  SimpleForm,
  TextInput,
  FileInput,
  required,
  ImageField
} from "react-admin";

export const TrainingsEdit = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <Edit title={<EditTitle source="name" />} {...props}>
      <SimpleForm variant="standard" margin="normal" toolbar={<EditToolbar />}>

        <TextInput source='title' label='Title' validate={required()} />
        <TextInput source='description' label='Description' validate={required()} multiline />

        <FileInput
            source="thumbnail_file"
            accept="image/*"
            label="Thumbnail"
            placeholder={<FileUploaderPlaceholder />}
            validate={required()}
            fullWidth
          >
            <ImageField source="src" />
        </FileInput>

        <FileInput
          source="video_file"
          accept="video/*"
          label="Video"
          validate={[required()]}
          placeholder={<FileUploaderPlaceholder />}
          fullWidth
        >
          <VideoInput source="src" />
        </FileInput>

      </SimpleForm>
    </Edit>
    </>
  );
}