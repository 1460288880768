export const validateUserPassword = (value) => {
  if (!value) { return; }
  const errors = [];
  if (!value?.match(/[A-Z]/)) {
    errors.push('Must contain at least one uppercase letter');
  }
  if (!value?.match(/[a-z]/)) {
    errors.push('Must contain at least one lowercase letter');
  }
  if (!value?.match(/[0-9]/)) {
    errors.push('Must contain at least one number');
  }
  if (!value?.match(/[\^$*.[\]{}()?"!@#%&/,><':;|_~`]/)) {
    errors.push('Must contain at least one special character');
  }
  return errors.length > 0 ? errors.join(', ') : undefined;
};