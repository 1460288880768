import ColorLensIcon from '@mui/icons-material/ColorLens';

import { CastColorsList } from './list';
import { CastColorsEdit } from './edit';
import { CastColorsShow } from './show';
import { CastColorCreate } from './create';

export default {
  name: "cast_colors",
  options: { label: "Cast Colors", menuParent: "cast_color" },
  icon: ColorLensIcon,
  list: CastColorsList,
  show: CastColorsShow,
  create: CastColorCreate,
  edit: CastColorsEdit,
};
