import React from "react";
import { EditTitle } from "../../../components/AppBar/Titles";
import { EditToolbar } from "../../../components/Toolbar/EditToolbar";
import { FileUploaderPlaceholder } from "components/FileUploaderPlaceholder";
import {
  Edit,
  required,
  FileInput,
  ImageField,
  SimpleForm,
} from "react-admin";
import Breadcrumbs from "components/Breadcrumbs";

export const CastTypePatternColorMappingEdit = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <Edit title={<EditTitle source="name" />} {...props}>
      <SimpleForm variant="standard" margin="normal" toolbar={<EditToolbar hidedelete />}>

        <FileInput
            source="thumbnail_file"
            accept="image/*"
            label="Thumbnail"
            validate={required()}
            placeholder={<FileUploaderPlaceholder />}
            fullWidth
          >
            <ImageField source="src" />
        </FileInput>

      </SimpleForm>
    </Edit>
    </>
  );
}