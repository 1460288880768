import LiveHelpIcon from '@mui/icons-material/LiveHelp';

import { FrequentlyAskedQuestionsList } from './list';
import { FrequentlyAskedQuestionsEdit } from './edit';
import { FrequentlyAskedQuestionsShow } from './show';
import { FrequentlyAskedQuestionCreate } from './create';

export default {
  name: "frequently_asked_questions",
  options: { label: "FAQ", menuParent: "frequently_asked_questions" },
  icon: LiveHelpIcon,
  list: FrequentlyAskedQuestionsList,
  show: FrequentlyAskedQuestionsShow,
  create: FrequentlyAskedQuestionCreate,
  edit: FrequentlyAskedQuestionsEdit,
};
