import React from "react";
import { EditTitle } from "../../../components/AppBar/Titles";
import { EditToolbar } from "../../../components/Toolbar/EditToolbar";
import {
  Edit,
  SimpleForm,
  SelectInput
} from "react-admin";
import Breadcrumbs from "components/Breadcrumbs";

export const TicketsEdit = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <Edit title={<EditTitle source="name" />} {...props}>
      <SimpleForm variant="standard" margin="normal" toolbar={<EditToolbar hidedelete />}>
        <SelectInput
            label="Status"
            source="status"
            choices={[
              { id: 'open', name: "Open" },
              { id: 'resolved', name: "Resolved" },
            ]}
        />
      </SimpleForm>
    </Edit>
    </>
  );
}