import React from "react";
import { EditTitle } from "../../../components/AppBar/Titles";
import { EditToolbar } from "../../../components/Toolbar/EditToolbar";
import {
  Edit,
  SimpleForm,
  TextInput,
  required
} from "react-admin";
import Breadcrumbs from "components/Breadcrumbs";

export const FrequentlyAskedQuestionsEdit = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <Edit title={<EditTitle source="name" />} {...props}>
      <SimpleForm variant="standard" margin="normal" toolbar={<EditToolbar />}>

        <TextInput source='question' label='Question' validate={required()} multiline />
        <TextInput source='answer' label='Answer' validate={required()} multiline />

      </SimpleForm>
    </Edit>
    </>
  );
}