import React from 'react';
import { VideoInput, VideoField } from 'components/Video/VideoComponents';
import { FileUploaderPlaceholder } from 'components/FileUploaderPlaceholder';
import {
  TextInput,
  FileInput,
  required,
  TextField,
  useRecordContext,
} from 'react-admin';

export const ConditionalInput = () => {
  const record = useRecordContext();
  if (record?.value_type === 'video') {
    return (
      <FileInput
        source='value_file'
        accept='video/*'
        label='Video'
        validate={[required()]}
        placeholder={<FileUploaderPlaceholder />}
        fullWidth
      >
        <VideoInput source='src' />
      </FileInput>
    );
  }
  return <TextInput source='value' label='Value' multiline />;
};

export const ConditionalField = () => {
  const record = useRecordContext();
  if (record?.value_type === 'video') {
    return <VideoField source="value.url" label="Video" />;
  }
  return <TextField source="value" label="Value" />;
};