import LocalShippingIcon from '@mui/icons-material/LocalShipping';

import { OrdersList } from './list';
import { OrdersEdit } from './edit';
import { OrdersShow } from './show';

export default {
  name: "orders",
  options: { label: "Orders", menuParent: "order" },
  icon: LocalShippingIcon,
  list: OrdersList,
  show: OrdersShow,
  // create: ClinicCreate,
  edit: OrdersEdit,
};
