import DeblurIcon from '@mui/icons-material/Deblur';

import { CastTypePatternColorMappingList } from './list';
import { CastTypePatternColorMappingEdit } from './edit';
import { CastTypePatternColorMappingShow } from './show';

export default {
  name: "cast_type_pattern_color_mappings",
  options: { label: "TPC Mappings", menuParent: "_cast_type_pattern_color_mappings" },
  icon: DeblurIcon,
  list: CastTypePatternColorMappingList,
  show: CastTypePatternColorMappingShow,
  edit: CastTypePatternColorMappingEdit,
};
