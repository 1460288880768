import PersonIcon from '@mui/icons-material/Person';

import { UsersList } from './list';
import { UsersEdit } from './edit';
import { UsersShow } from './show';
import { UserCreate } from './create';

export default {
  name: "users_metadata",
  options: { label: "Users", menuParent: "user" },
  icon: PersonIcon,
  list: UsersList,
  show: UsersShow,
  create: UserCreate,
  edit: UsersEdit,
};
