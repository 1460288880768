import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import { ViewTitle } from "../../../components/AppBar/Titles";
import { 
  Show,
  TextField,
  BooleanField,
  TabbedShowLayout,
  Tab,
  ReferenceField,
  ReferenceArrayField,
  Datagrid
} from "react-admin";

export const FractureLocationsShow = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent}/>
      <Show title={<ViewTitle source="name" />} {...props}>
        <TabbedShowLayout>

          <Tab label="Details">
            <TextField source="location" label="Location" />
            <BooleanField source="is_available" label="Is Available?" />

          </Tab>

          <Tab label="Cast Types">
            <ReferenceArrayField source="types_ids" reference="cast_types" fullWidth>
              <Datagrid>
                <ReferenceField 
                  source="id"
                  reference="cast_types"
                  label="Type"
                  link={(record, reference) =>
                    `/${reference}/${record.id}/show`
                  }
                >
                  <TextField source="name" label="Name" />
                </ReferenceField>
                <BooleanField source='is_available' label="Is Available?" />
              </Datagrid>
            </ReferenceArrayField>
          </Tab>

        </TabbedShowLayout>
      </Show>
  </>
);
}