import PatternIcon from '@mui/icons-material/Pattern';

import { CastPatternsList } from './list';
import { CastPatternsEdit } from './edit';
import { CastPatternsShow } from './show';
import { CastPatternCreate } from './create';

export default {
  name: "cast_patterns",
  options: { label: "Cast Patterns", menuParent: "cast_pattern" },
  icon: PatternIcon,
  list: CastPatternsList,
  show: CastPatternsShow,
  create: CastPatternCreate,
  edit: CastPatternsEdit,
};
