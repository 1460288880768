import React from "react";
import { DebouncedTextField } from "components/Fields";
import FilterContainer from "components/containers/Filter";
import { ReferenceInput, AutocompleteInput } from 'react-admin';


const UserFilter = (props) => (
  <FilterContainer {...props} variant="standard" margin="normal" disableDates>
    <DebouncedTextField label="Email" source="email" />
    <DebouncedTextField label="Therapist Name" source="therapist_name" />

    <ReferenceInput
        source="clinic_id"
        reference="clinics"
        label="Clinic"
        filterToQuery={(searchText) => ({
            name: searchText,
            id: parseInt(searchText),
            _autocomplete: true,
          })}
    >
        <AutocompleteInput
          matchSuggestion={(filterValue, suggestion) => true}
          optionText={(choice) => {
            return `${choice?.id ? `${choice.name} (${choice.id})` : "None" }`
          }}
        />
    </ReferenceInput>

  </FilterContainer>
);

export default UserFilter;
