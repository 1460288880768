import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import { makeStyles } from '@material-ui/core/styles';
import { ViewTitle } from "../../../components/AppBar/Titles";
import { VideoField } from "components/Video/VideoComponents";
import { 
  Show,
  TextField,
  ImageField,
  SimpleShowLayout, 
} from "react-admin";

const useStyles = makeStyles({
  customImage: {
    '& img': {
      width: '200px',
      height: '200px',
    }
  },
})

export const TrainingsShow = (props) => {
  const classes = useStyles();
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent}/>
      <Show title={<ViewTitle source="name" />} {...props}>
        <SimpleShowLayout>

          <TextField source="title" label="Title" />
          <TextField source="description" label="Description" />
          <ImageField source="thumbnail.url" label="Thumbnail" />
          <VideoField source="video.url" label="Video" />

        </SimpleShowLayout>
      </Show>
  </>
);
}