import React from "react";
import { CreateToolbar } from "components/Toolbar/CreateToolbar";
import { FileUploaderPlaceholder } from "components/FileUploaderPlaceholder";
import { 
  Create,
  SimpleForm,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  TextInput,
  BooleanInput,
  FileInput,
  ImageField,
  required
} from "react-admin";

export const CastTypeCreate = (props) => (
  <Create {...props} title="Create Cast Type">
    <SimpleForm variant="standard" margin="normal" toolbar={<CreateToolbar />}>

      <TextInput source='name' label='Name' validate={required()} />
      <BooleanInput source='is_available' label='Is Available?' validate={required()} />

      <ReferenceArrayInput
          label="Fracture Locations"
          source="locations_ids"
          reference="fracture_locations"
          validate={required()}
          perPage={500}
          filterToQuery={(searchText) => ({
            id: searchText,
            name: searchText,
            _autocomplete: true,
          })}
          sort={{ field: "id", order: "ASC" }}
          fullWidth
          allowEmpty
        >
          <AutocompleteArrayInput
            matchSuggestion={(filterValue, suggestion) => true}
            optionText={(choice) =>
              choice && choice.id
                ? `${choice.location} (${choice.id})`
                : "None"
            }
          />
      </ReferenceArrayInput>

      <ReferenceArrayInput
          label="Cast Patterns"
          source="patterns_ids"
          reference="cast_patterns"
          perPage={500}
          filterToQuery={(searchText) => ({
            id: searchText,
            name: searchText,
            _autocomplete: true,
          })}
          sort={{ field: "id", order: "ASC" }}
          fullWidth
          allowEmpty
        >
          <AutocompleteArrayInput
            matchSuggestion={(filterValue, suggestion) => true}
            optionText={(choice) =>
              choice && choice.id
                ? `${choice.name} (${choice.id})`
                : "None"
            }
          />
      </ReferenceArrayInput>

        <FileInput
            source="thumbnail_file"
            accept="image/*"
            label="Thumbnail"
            validate={required()}
            placeholder={<FileUploaderPlaceholder />}
            fullWidth
          >
            <ImageField source="src" />
        </FileInput>

    </SimpleForm>
  </Create>
  );

