import React from "react";
import { CreateToolbar } from "components/Toolbar/CreateToolbar";
import { 
  Create,
  SimpleForm,
  TextInput,
  required
} from "react-admin";

export const FrequentlyAskedQuestionCreate = (props) => (
  <Create {...props} title="Create Frequently Asked Question">
    <SimpleForm variant="standard" margin="normal" toolbar={<CreateToolbar />}>

      <TextInput source='question' label='Question' validate={required()} multiline />
      <TextInput source='answer' label='Answer' validate={required()} multiline />

    </SimpleForm>
  </Create>
  );

