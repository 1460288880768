import { Resource } from 'react-admin';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';

import clinicViews from './Main/index.js';

const clinicsRoutes = [
  <Resource
    name='clinic'
    icon={LocalHospitalIcon}
    options={{ label: 'Clinics', isMenuParent: true }}
  />,
  <Resource {...clinicViews} />,
];
export default clinicsRoutes;
