import React from "react";
import { CreateToolbar } from "components/Toolbar/CreateToolbar";
import { FileUploaderPlaceholder } from "components/FileUploaderPlaceholder";
import {
  Create,
  SimpleForm,
  FileInput,
  ImageField,
  TextInput,
  required
} from "react-admin";

export const ClinicCreate = (props) => (
  <Create {...props} title="Create Clinic">
    <SimpleForm variant="standard" margin="normal" toolbar={<CreateToolbar />}>
      <TextInput source="name" label="Name" fullWidth />
      <TextInput source="country" label="Country" fullWidth />
      <TextInput source="state" label="State" fullWidth />
      <TextInput source="city" label="City" fullWidth />
      <TextInput source="address" label="Address" fullWidth />
      <TextInput source="zip_code" label="Zip Code" fullWidth />
      
      <FileInput
          source="logo_url_file"
          accept="image/*"
          label="Logo Thumbnail"
          placeholder={<FileUploaderPlaceholder />}
          validate={required()}
          fullWidth
        >
          <ImageField source="src" />
      </FileInput>

    </SimpleForm>
  </Create>
  );

