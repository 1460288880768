import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import { ViewTitle } from "../../../components/AppBar/Titles";
import { Show, SimpleShowLayout, TextField, ImageField } from "react-admin";

export const ClinicsShow = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent}/>
      <Show title={<ViewTitle source="name" />} {...props}>
        <SimpleShowLayout>
          <TextField source="id" label="ID" fullWidth />
          <TextField source="name" label="Name" fullWidth />
          <TextField source="country" label="Country" fullWidth />
          <TextField source="state" label="State" fullWidth />
          <TextField source="city" label="City" fullWidth />
          <TextField source="address" label="Address" fullWidth />
          <TextField source="zip_code" label="Zip Code" fullWidth />
          <ImageField source="logo_url.url" label="Logo Thumbnail" sortable={false} />
        </SimpleShowLayout>
      </Show>
  </>
);
}