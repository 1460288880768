import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import { ViewTitle } from "../../../components/AppBar/Titles";
import { FormattedTextFieldWithLabel } from 'components/Fields/FormattedTextField';
import { 
  Show,
  TextField,
  ReferenceField,
  FunctionField,
  SimpleShowLayout
} from "react-admin";

export const TicketsShow = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent}/>
      <Show title={<ViewTitle source="name" />} {...props}>
        <SimpleShowLayout>
          <TextField source='subject' label="Subject" />
          <TextField source='uid' label="Unique Identifier" />
          <TextField source='description' label="Description" />
          <FormattedTextFieldWithLabel source='status' label="Status" />

          <ReferenceField 
            source="owner_id"
            reference="users_metadata"
            label="User"
            link={(record, reference) =>
              `/${reference}/${record.owner_id}/show`
            }
            >
            <FunctionField render={(record) => `${record.therapist_name}`} />
          </ReferenceField>
        </SimpleShowLayout>
      </Show>
  </>
);
}