import React from 'react';
import ClinicFilter from './filter';
import { List, TextField } from 'react-admin';
import Breadcrumbs from 'components/Breadcrumbs';
import DataGridList from 'components/containers/List';
import EmptyPage from 'components/Page/Empty/EmptyPage';
import { Paginator } from 'components/Paginator/Paginator';
import ListActions from 'components/ListAction/ListActions';

export const ClinicsList = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <List
      title='Clinics'
      filters={<ClinicFilter />}
      pagination={<Paginator />}
      perPage={15}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
      empty={<EmptyPage create />}
      actions={<ListActions listExporterType='Clinics' />}
    >
      <DataGridList edit show >
        <TextField source='name' label="Name" />

      </DataGridList>
      
    </List>
    </>
    )
};
