import React from 'react';
import SettingFilter from './filter';
import { ConditionalField } from './components';
import Breadcrumbs from 'components/Breadcrumbs';
import DataGridList from 'components/containers/List';
import EmptyPage from 'components/Page/Empty/EmptyPage';
import { Paginator } from 'components/Paginator/Paginator';
import ListActions from 'components/ListAction/ListActions';
import { 
  List,
  TextField, 
  FunctionField
} from 'react-admin';

export const SettingsList = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <List
      title='Settings'
      filters={<SettingFilter />}
      pagination={<Paginator />}
      perPage={15}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
      empty={<EmptyPage create />}
      actions={<ListActions listExporterType='Settings' hideCreateButton={true} />}
    >
      <DataGridList edit show>

        <TextField source="key" value="Key" />
        <FunctionField
            label="Value"
            render={_ => <ConditionalField />}
        />
        <TextField source="description" value="Description" />

      </DataGridList>
      
    </List>
    </>
    )
};