import React from "react";
import { DebouncedTextField } from "components/Fields";
import FilterContainer from "components/containers/Filter";

const TrainingFilter = (props) => (
  <FilterContainer {...props} variant="standard" margin="normal" disableDates>

  </FilterContainer>
);

export default TrainingFilter;
