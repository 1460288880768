import { Resource } from 'react-admin';
import AccountTreeIcon from '@mui/icons-material/AccountTree';

import castTypeViews from './Main/index.js';

const castTypesRoutes = [
  <Resource
    name='cast_type'
    icon={AccountTreeIcon}
    options={{ label: 'Cast Types', isMenuParent: true }}
  />,
  <Resource {...castTypeViews} />,
];
export default castTypesRoutes;
