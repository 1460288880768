import React from "react";
import { addDays, isAfter } from 'date-fns';
import { EditTitle } from "../../../components/AppBar/Titles";
import { EditToolbar } from "../../../components/Toolbar/EditToolbar";
import {
  Edit,
  SimpleForm,
  SelectInput,
  DateInput
} from "react-admin";
import Breadcrumbs from "components/Breadcrumbs";

const validateFutureDate = (value) => {
  const today = new Date();
  return isAfter(new Date(value), addDays(today, -1)) ? undefined : 'Delivery date must be in the future';
};

export const OrdersEdit = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <Edit title={<EditTitle source="name" />} {...props}>
      <SimpleForm variant="standard" margin="normal" toolbar={<EditToolbar hidedelete />}>

        <SelectInput
          label="Order Status"
          source="order_status"
          choices={[
            { id: 'initialDraft', name: "Initial Draft" },
            { id: 'submitted', name: "Submitted" },
            { id: 'inProduction', name: "In Production" },
            { id: 'readyForDispatch', name: "Ready for Dispatch" },
            { id: 'enRoute', name: "En Route" },
            { id: 'delivered', name: "Delivered" },
            { id: 'cancelled', name: "Cancelled" },
          ]}
        />

        <DateInput source='delivery_date' label='Delivery Date' validate={validateFutureDate} />
      </SimpleForm>
    </Edit>
    </>
  );
}