import { Resource } from 'react-admin';
import DeblurIcon from '@mui/icons-material/Deblur';

import castTypePatternColorMappingViews from './Main/index.js';

const castTypePatternColorMappingsRoutes = [
  <Resource
    name='_cast_type_pattern_color_mappings'
    icon={DeblurIcon}
    options={{ label: 'Type Pattern Color Mappings', isMenuParent: true }}
  />,
  <Resource {...castTypePatternColorMappingViews} />,
];
export default castTypePatternColorMappingsRoutes;
