import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import { DateFieldShow } from 'components/Fields';
import { ViewTitle } from "../../../components/AppBar/Titles";
import { ShowBoxTextField } from 'components/TextField/BoxTextField';
import { FormattedTextFieldWithLabel } from 'components/Fields/FormattedTextField';
import { 
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  FunctionField,
  UrlField, 
} from "react-admin";

export const OrdersShow = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent}/>
      <Show title={<ViewTitle source="name" />} {...props}>
        <SimpleShowLayout>
          <FormattedTextFieldWithLabel source="order_status" label="Order Status" fullWidth />
          <FormattedTextFieldWithLabel source="limb" label="Scanned Limb" fullWidth />
          <TextField source="status_change_date_time" label="Status Change Date Time" fullWidth />
          <UrlField source="scan_file_url" label="Scan File URL" fullWidth />
          <UrlField source="cast_file_url" label="Cast File URL" fullWidth />

          <ReferenceField 
          source="clinic_id"
          reference="clinics"
          label="Clinic Name"
          link={(record, reference) =>
            `/${reference}/${record.clinic_id}/show`
          }
        >
          <FunctionField render={(record) => `${record.name}`} />
        </ReferenceField>

          <ReferenceField 
            source="user_id"
            reference="users_metadata"
            label="Therapist"
            link={(record, reference) =>
              `/${reference}/${record.user_id}/show`
            }
          >
            <FunctionField render={(record) => `${record.therapist_name}`} />
          </ReferenceField>

          <ReferenceField 
            source="fracture_location_id"
            reference="fracture_locations"
            label="Fracture Location"
            link={(record, reference) =>
              `/${reference}/${record.fracture_location_id}/show`
            }
          >
            <FunctionField render={(record) => `${record.location}`} />
          </ReferenceField>

          <ReferenceField 
            source="cast_type_id"
            reference="cast_types"
            label="Cast Type"
            link={(record, reference) =>
              `/${reference}/${record.cast_type_id}/show`
            }
          >
            <FunctionField render={(record) => `${record.name}`} />
          </ReferenceField>

          <ReferenceField 
            source="cast_pattern_id"
            reference="cast_patterns"
            label="Cast Pattern"
            link={(record, reference) =>
              `/${reference}/${record.cast_pattern_id}/show`
            }
          >
            <FunctionField render={(record) => `${record.name}`} />
          </ReferenceField>

          <ReferenceField 
            source="cast_color_id"
            reference="cast_colors"
            label="Cast Color"
            link={(record, reference) =>
              `/${reference}/${record.cast_color_id}/show`
            }
          >
            <FunctionField render={(record) => `${record.name}`} />
          </ReferenceField>

          <TextField source="full_name" label="Full Name" fullWidth />
          <FormattedTextFieldWithLabel source="gender" label="Gender" fullWidth />
          <TextField source="year_of_birth" label="Year Of Birth" fullWidth />
          <TextField source="weight" label="Weight" fullWidth />
          <FormattedTextFieldWithLabel source="weight_unit" label="Weight Unit" fullWidth />
          <TextField source="height" label="Height" fullWidth />
          <FormattedTextFieldWithLabel source="height_unit" label="Height Unit" fullWidth />
          <TextField source="phone_number" label="Phone Number" fullWidth />
          <DateFieldShow source="delivery_date" label="Delivery Date" fullWidth  />
          <ShowBoxTextField source='notes' label='Notes' fullWidth  />

        </SimpleShowLayout>
      </Show>
  </>
);
}