import SupportAgentIcon from '@mui/icons-material/SupportAgent';

import { TicketsList } from './list';
import { TicketsEdit } from './edit';
import { TicketsShow } from './show';

export default {
  name: "tickets",
  options: { label: "Tickets", menuParent: "tickets" },
  icon: SupportAgentIcon,
  list: TicketsList,
  show: TicketsShow,
  // create: TicketsCreate,
  edit: TicketsEdit,
};
