import React from "react";
import { CreateToolbar } from "components/Toolbar/CreateToolbar";
import { 
  BooleanInput,
  Create,
  SimpleForm,
  TextInput,
  required,
  ReferenceArrayInput,
  AutocompleteArrayInput
} from "react-admin";

export const FractureLocationCreate = (props) => (
  <Create {...props} title="Create Fracture Location">
    <SimpleForm variant="standard" margin="normal" toolbar={<CreateToolbar />}>
      <TextInput source="location" label="Location" validate={required()} fullWidth />
      <BooleanInput source="is_available" label="Is Available?" validate={required()} fullWidth />

      <ReferenceArrayInput
          label="Cast Types"
          source="types_ids"
          reference="cast_types"
          perPage={500}
          filterToQuery={(searchText) => ({
            id: searchText,
            name: searchText,
            _autocomplete: true,
          })}
          sort={{ field: "id", order: "ASC" }}
          fullWidth
          allowEmpty
        >
          <AutocompleteArrayInput
            matchSuggestion={(filterValue, suggestion) => true}
            optionText={(choice) =>
              choice && choice.id
                ? `${choice.type} (${choice.id})`
                : "None"
            }
          />
        </ReferenceArrayInput>

    </SimpleForm>
  </Create>
  );

