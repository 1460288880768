import React from 'react';
import Breadcrumbs from 'components/Breadcrumbs';
import FrequentlyAskedQuestionFilter from './filter';
import DataGridList from 'components/containers/List';
import EmptyPage from 'components/Page/Empty/EmptyPage';
import { Paginator } from 'components/Paginator/Paginator';
import ListActions from 'components/ListAction/ListActions';
import { BoxTextField } from 'components/TextField/BoxTextField';
import { 
  List, 
} from 'react-admin';

export const FrequentlyAskedQuestionsList = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <List
      title='FrequentlyAskedQuestions'
      filters={<FrequentlyAskedQuestionFilter />}
      pagination={<Paginator />}
      perPage={15}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
      empty={<EmptyPage create />}
      actions={<ListActions listExporterType='FrequentlyAskedQuestions' hideCreateButton={false} />}
    >
      <DataGridList edit show>

        <BoxTextField source='question' label="Question" />
        <BoxTextField source='answer' label="Answer" />

      </DataGridList>
      
    </List>
    </>
    )
};