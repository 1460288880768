import React from 'react';
import TicketFilter from './filter';
import Breadcrumbs from 'components/Breadcrumbs';
import DataGridList from 'components/containers/List';
import EmptyPage from 'components/Page/Empty/EmptyPage';
import { Paginator } from 'components/Paginator/Paginator';
import ListActions from 'components/ListAction/ListActions';
import { FormattedTextField } from 'components/Fields/FormattedTextField';
import { 
  FunctionField,
  ReferenceField,
  List, 
  TextField 
} from 'react-admin';

export const TicketsList = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <List
      title='Tickets'
      filters={<TicketFilter />}
      pagination={<Paginator />}
      perPage={15}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
      empty={<EmptyPage create />}
      actions={<ListActions listExporterType='Tickets' hideCreateButton={true} />}
    >
      <DataGridList edit show>
        <TextField source='subject' label="Subject" />
        <TextField source='uid' label="Unique Identifier" />
        <FormattedTextField source='status' label="Status" />

        <ReferenceField 
          source="owner_id"
          reference="users_metadata"
          label="User"
          link={(record, reference) =>
            `/${reference}/${record.owner_id}/show`
          }
        >
          <FunctionField render={(record) => `${record.therapist_name}`} />
        </ReferenceField>

      </DataGridList>
      
    </List>
    </>
    )
};