import React from 'react';
import CastTypeFilter from './filter';
import Breadcrumbs from 'components/Breadcrumbs';
import DataGridList from 'components/containers/List';
import { makeStyles } from '@material-ui/core/styles';
import EmptyPage from 'components/Page/Empty/EmptyPage';
import { Paginator } from 'components/Paginator/Paginator';
import ListActions from 'components/ListAction/ListActions';
import { 
  List,
  TextField,
  ImageField,
  BooleanField,
} from 'react-admin';

const useStyles = makeStyles({
  customImage: {
    '& img': {
      width: '55px',
      height: '55px',
    }
  },
});

export const CastTypesList = (props) => {
  const classes = useStyles();
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <List
      title='Cast Types'
      filters={<CastTypeFilter />}
      pagination={<Paginator />}
      perPage={15}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
      empty={<EmptyPage create />}
      actions={<ListActions listExporterType='Cast Types' />}
    >
      <DataGridList edit show>
        <TextField source='name' label='Name' />
        <BooleanField source='is_available' label='Is Available?' />
        <ImageField source="thumbnail.url" label="Thumbnail" className={classes.customImage} sortable={false} />
      </DataGridList>
      
    </List>
    </>
    )
};