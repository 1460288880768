import { Resource } from 'react-admin';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';

import fractureLocationViews from './Main/index.js';

const fractureLocationsRoutes = [
  <Resource
    name='fracture_location'
    icon={EmojiPeopleIcon}
    options={{ label: 'Fracture Locations', isMenuParent: true }}
  />,
  <Resource {...fractureLocationViews} />,
];
export default fractureLocationsRoutes;
