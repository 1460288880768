import SettingsIcon from '@mui/icons-material/Settings';

import { SettingsList } from './list';
import { SettingsEdit } from './edit';
import { SettingsShow } from './show';

export default {
  name: "settings",
  options: { label: "Settings", menuParent: "_settings" },
  icon: SettingsIcon,
  list: SettingsList,
  show: SettingsShow,
  // create: SettingCreate,
  edit: SettingsEdit,
};
