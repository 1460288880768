import { Admin } from "react-admin";
import Menu from "components/Menu/Menu";
import dataProvider from "providers/Data";
import MomentUtils from "@date-io/moment";
import Login from "components/Login/Login";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import MyLayout from "components/AppBar/Layout";
import englishMessages from "ra-language-english";
import { ThemeContext } from "themes/ThemeContext";
import polyglotI18nProvider from "ra-i18n-polyglot";
import authProvider from "providers/Authentication";
import Dashboard from "components/Dashboard/Dashboard";
import { MyDarkTheme, MyLightTheme } from "themes/Themes";
import BackdropLoader from "components/Loaders/BackdropLoader";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import "./App.css";
import 'react-toastify/dist/ReactToastify.css';

import usersRoutes from "resources/Users";
import ordersRoutes from "resources/Orders";
import ticketsRoutes from "resources/Tickets";
import clinicsRoutes from "resources/Clinics";
import settingsRoutes from 'resources/Settings';
import trainingsRoutes from 'resources/Trainings';
import castTypesRoutes from 'resources/CastTypes';
import castColorsRoutes from "resources/CastColors";
import castPatternsRoutes from "resources/CastPatterns";
import fractureLocationsRoutes from "resources/FractureLocations";
import frequentlyAskedQuestionsRoutes from "resources/FrequentlyAskedQuestions";
import castTypePatternColorMappingsRoutes from "resources/CastTypePatternColorMappings";

let previousUrlPath;

const App = () => {
  const history = useHistory();
  const [showLoader, setShowLoader] = useState(false);
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") ? localStorage.getItem("theme") : "dark"
  );

  // if the current page is home (dashboard), don't show the loader:
  useEffect(() => {
    previousUrlPath = history.location.hash;
    if (
      history.location.hash.charAt(history.location.hash.length - 1) === "/"
    ) {
      setShowLoader(false);
    } else {
      setShowLoader(true);
    }
  }, []); // eslint-disable-line

  // whenever the url changes, show loader (unless it is the dashboard):
  useEffect(() => {
    return history.listen(() => {
      // the following code prevents the bug where the user
      // clicks the browser back button from a create/show/edit page and add a filter:
      if (
        previousUrlPath.split("").filter((letter) => letter === "/").length >=
          2 &&
        history.location.hash.split("").filter((letter) => letter === "/")
          .length < 2
      ) {
        window.location.reload();
      }
      previousUrlPath = history.location.hash;

      // here we are showing the loader if the url is not home (dashboard):
      if (
        history.location.hash.charAt(history.location.hash.length - 1) === "/"
      ) {
        setShowLoader(false);
      } else {
        setShowLoader(true);
      }
    });
  }, [history]);

  const i18nProvider = polyglotI18nProvider(() => englishMessages, "en", {
    allowMissing: true,
  });
  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <MuiPickersUtilsProvider  utils={MomentUtils}>
      <Admin
        dashboard={Dashboard}
        layout={MyLayout}
        theme={theme === "dark" ? MyDarkTheme : MyLightTheme}
        dataProvider={dataProvider}
        authProvider={authProvider}
        loginPage={Login}
        // menu={Menu}
        i18nProvider={i18nProvider}
      >
        {showLoader ? <BackdropLoader /> : <></>}

        {ordersRoutes}
        {clinicsRoutes}
        {usersRoutes}
        {fractureLocationsRoutes}
        {castTypesRoutes}
        {castPatternsRoutes}
        {castColorsRoutes}
        {castTypePatternColorMappingsRoutes}
        {ticketsRoutes}
        {frequentlyAskedQuestionsRoutes}
        {trainingsRoutes}
        {settingsRoutes}
        
      </Admin>
      </MuiPickersUtilsProvider>
      <ToastContainer/>
    </ThemeContext.Provider>
  );
};

export default App;
