import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import { EditTitle } from "../../../components/AppBar/Titles";
import { EditToolbar } from "../../../components/Toolbar/EditToolbar";
import { FileUploaderPlaceholder } from "components/FileUploaderPlaceholder";
import {
  Edit,
  SimpleForm,
  TextInput,
  FileInput,
  ImageField,
  required
} from "react-admin";

export const ClinicsEdit = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <Edit title={<EditTitle source="name" />} {...props}>
      <SimpleForm variant="standard" margin="normal" toolbar={<EditToolbar hidedelete />}>
        
        <TextInput source="name" label="Name" fullWidth />
        <TextInput source="country" label="Country" fullWidth />
        <TextInput source="state" label="State" fullWidth />
        <TextInput source="city" label="City" fullWidth />
        <TextInput source="address" label="Address" fullWidth />
        <TextInput source="zip_code" label="Zip Code" fullWidth />

        <FileInput
            source="logo_url_file"
            accept="image/*"
            label="Logo Thumbnail"
            placeholder={<FileUploaderPlaceholder />}
            validate={required()}
            fullWidth
          >
            <ImageField source="src" />
        </FileInput>
        
      </SimpleForm>
    </Edit>
    </>
  );
}