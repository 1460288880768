import { Resource } from 'react-admin';
import PersonIcon from '@mui/icons-material/Person';

import userViews from './Main/index.js';

const usersRoutes = [
  <Resource
    name='user'
    icon={PersonIcon}
    options={{ label: 'Users', isMenuParent: true }}
  />,
  <Resource {...userViews} />,
];
export default usersRoutes;
