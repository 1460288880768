import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import { ViewTitle } from "../../../components/AppBar/Titles";
import { ShowBoxTextField } from 'components/TextField/BoxTextField';
import { 
  Show,
  SimpleShowLayout
} from "react-admin";

export const FrequentlyAskedQuestionsShow = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent}/>
      <Show title={<ViewTitle source="name" />} {...props}>
        <SimpleShowLayout>

          <ShowBoxTextField source='question' label="Question" />
          <ShowBoxTextField source='answer' label="Answer" />

        </SimpleShowLayout>
      </Show>
  </>
);
}