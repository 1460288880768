import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';

import { FractureLocationsList } from './list';
import { FractureLocationsEdit } from './edit';
import { FractureLocationsShow } from './show';
import { FractureLocationCreate } from './create';

export default {
  name: "fracture_locations",
  options: { label: "Fracture Locations", menuParent: "fracture_location" },
  icon: EmojiPeopleIcon,
  list: FractureLocationsList,
  show: FractureLocationsShow,
  create: FractureLocationCreate,
  edit: FractureLocationsEdit,
};
