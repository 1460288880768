import config, { defaultTimezone } from "../config";
import { fetchUtils } from "react-admin";
import simpleRestProvider from "./SimpleRestProvider";
import { set } from "lodash";
import moment from 'moment-timezone';

const loadFiles = async (record) => {
	for (let [attribute, value] of Object.entries(record)) {
		if (value && value.hasOwnProperty("url")) {
			
			const { key, url } = value; // eslint-disable-line
			// const request = new Request(`${url}?${(new Date()).getTime()}`);
			// const response = await fetch(request);
			// const blob = await response.blob();
			record[`${attribute}_file`] = { src: url,key };
		}else if(Array.isArray(value)&& value.every(obj => typeof obj === 'object' && obj !== null)){
			const parentAttribute=attribute;
			for(let [index,object] of value.entries()){
                for(let [attribute, value] of Object.entries(object)){
					if (value && value.hasOwnProperty("url")) {
						const { key, url } = value; // eslint-disable-line
						set(record,`${parentAttribute}[${index}].${attribute}_file`,{ src: url,key });
				}
			}}
		}
	}
};

const httpClient = (url, options = {}) => {
	if (!options.headers) {
		options.headers = new Headers({ Accept: "application/json" });
	}

	const token = localStorage.getItem("token");
	options.headers.set("X-Auth-Token", token);
	return fetchUtils.fetchJson(url, options);
};

const _dataProvider = simpleRestProvider(`${config.API_URL}/resources`, httpClient);


const dataProvider = {
	..._dataProvider,
	getList: async (resource, params) => {
		// When the admin user enters any date and time, the time is regarded as being in the local time zone of the device and is converted to the configured defaultTimezone.
		// (For example: If the input time is 11:00 and the local time zone is GMT+2, it will be converted to 09:00).
		// To avoid this we will set the timezone to be local until the end of the function.
		moment.tz.setDefault();
		Object.keys(params.filter).forEach(key => {
			// Check if value is a date, example of the required format: 2022-12-30T12:30:00.000Z
			if (moment(params.filter[key], moment.ISO_8601, true).isValid() && params.filter[key].endsWith('Z')) {
			  // defaultTimezone (that we defined on config.js) to UTC is being sent to the server
				// Convert back to the local time, to refer to the time and date the user entered without further conversion.
			  params.filter[key] = moment(params.filter[key]).tz(defaultTimezone, true).toDate().toISOString();		  
			}
		});

		// changing the filters that has '->' inside their source to prevent wrong filter object structure:
		for (const key in params.filter) {
			if (key.includes("->")) {
				params.filter[`${key.replace(/->/g, ".")}`] = params.filter[key];
				delete params.filter[key];
			}
		}

		const records = await _dataProvider.getList(resource, params);

		// convert the records data to be in default timezone
		records.data.forEach((dataObj) => {
			Object.keys(dataObj).forEach(key => {
				// in case of participants array for each session for example
				if (Array.isArray(dataObj[key]) && dataObj[key].length) {
					dataObj[key].forEach((value) => {
						Object.keys(value).forEach(key => {
							if (typeof value[key] !== 'string') return;
							// check if value is a date
							if (moment(value[key], moment.ISO_8601, true).isValid()) {	
								value[key] = moment.tz(value[key], defaultTimezone).toString();	  
							}
						});
					});
				};

				// for example additional info obj -> rescheduble until (sessions table)
				if (typeof dataObj[key] === 'object' && dataObj[key] && Object.keys(dataObj[key]).length) {
					Object.keys(dataObj[key]).forEach(innerKey => {
						if (typeof dataObj[key][innerKey] !== 'string') return;
						// check if value is a date
						if (moment(dataObj[key][innerKey], moment.ISO_8601, true).isValid()) {	
							dataObj[key][innerKey] = moment.tz(dataObj[key][innerKey], defaultTimezone).toString();	  
						}
					});
				}

				if (typeof dataObj[key] !== 'string') return;
				// check if value is a date
				if (moment(dataObj[key], moment.ISO_8601, true).isValid()) {	
					dataObj[key] = moment.tz(dataObj[key], defaultTimezone).toString();	  
				}
			});
		});

      // After we have finished handling the above case, it is required to return to the default timezone of the system.
			moment.tz.setDefault(defaultTimezone);

		return records;
	},
	getOne: async (resource, params) => {
		const record = await _dataProvider.getOne(resource, params);
		await loadFiles(record.data);
		return record;
	},
	getMetaData: async (resource, params) => {
		const data = await _dataProvider.getMetaData(resource, params);
		return data;
	},
};


export default dataProvider;
