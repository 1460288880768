import LocalHospitalIcon from '@mui/icons-material/LocalHospital';

import { ClinicsList } from './list';
import { ClinicsEdit } from './edit';
import { ClinicsShow } from './show';
import { ClinicCreate } from './create';

export default {
  name: "clinics",
  options: { label: "Clinics", menuParent: "clinic" },
  icon: LocalHospitalIcon,
  list: ClinicsList,
  show: ClinicsShow,
  create: ClinicCreate,
  edit: ClinicsEdit,
};
