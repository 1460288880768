import React from 'react';
import PropTypes from 'prop-types';
import { Labeled, TextField } from 'react-admin';

function transformCamelCaseToSpaces(text) {
    const words = text.match(/[A-Z]?[a-z]+/g) || [];
    return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export const FormattedTextFieldWithLabel = (props) => {
    const { label, source, record, ...rest } = props;

    let formattedValue = "";
    if (record && source) {
        const value = record[source];
        if (typeof value === 'string') {
            formattedValue = transformCamelCaseToSpaces(value);
        }
    }

    // If label isn't explicitly provided, format the source as the label
    const formattedLabel = label ? label : transformCamelCaseToSpaces(source);

    return (
        <Labeled label={formattedLabel} source={source} {...rest}>
            <TextField source={source} record={{ [source]: formattedValue }} />
        </Labeled>
    );
};

export const FormattedTextField = (props) => {
  const transformedProps = { ...props };

  if (props.record && props.source) {
      const value = props.record[props.source];
      if (typeof value === 'string') {
          transformedProps.record = {
              ...props.record,
              [props.source]: transformCamelCaseToSpaces(value),
          };
      }
  }

  return <TextField {...transformedProps} />;
};


FormattedTextField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired
};
