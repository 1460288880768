import { Resource } from 'react-admin';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

import orderViews from './Main/index.js';

const ordersRoutes = [
  <Resource
    name='order'
    icon={LocalShippingIcon}
    options={{ label: 'Orders', isMenuParent: true }}
  />,
  <Resource {...orderViews} />,
];
export default ordersRoutes;
