import { Resource } from 'react-admin';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';

import trainingsViews from './Main/index.js';

const trainingsRoutes = [
  <Resource
    name='_trainings'
    icon={OndemandVideoIcon}
    options={{ label: 'Trainings', isMenuParent: true }}
  />,
  <Resource {...trainingsViews} />,
];
export default trainingsRoutes;
