import React, { useContext } from "react";
import { LoginForm } from "./LoginForm";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeContext } from "../../themes/ThemeContext";
import { useLocation, useHistory } from "react-router-dom";
import { VerificationForm } from "./VerificationForm";
import Gero3DLogo from "../../assets/images/Logo/Gero3DLogo.svg";
import {
  useAuthProvider,
  useNotify,
  useSafeSetState,
  Login,
} from "react-admin";

const LiveExpertsLogin = (props) => {
  const { theme, setTheme } = useContext(ThemeContext); // eslint-disable-line

  const pageStyles = makeStyles({
    main: {
      background: "none",
      backgroundColor: theme === "dark" ? "#3f3f3fe6" : "#248ad3",
    },
    card: {
      padding: "50px",
      backgroundImage: `url(${Gero3DLogo})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "300px",
      backgroundPosition: "center -20px",
    },
    avatar: {
      margin: "3em",
    },
    icon: {
      display: "none",
    },
  });
  const { redirectTo } = props;

  const location = useLocation();
  const locationState = location.state;

  const history = useHistory();
  const nextPathName = locationState && locationState.nextPathname;

  const redirectUrl = redirectTo ? redirectTo : nextPathName || "/";

  const [step, setStep] = useSafeSetState("login");
  const [loading, setLoading] = useSafeSetState(false);

  const notify = useNotify();
  const authProvider = useAuthProvider();

  const pageClasses = pageStyles();
  const pageProps = {
    ...props,
    classes: pageClasses,
  };

  const handleLogin = (values) => {
    setLoading(true);
    authProvider
      .login(values)
      .then(() => {
        setLoading(false);
        // setStep("verify");
        history.push(redirectUrl);
      })
      .catch((error) => {
        setLoading(false);
        notify(
          typeof error === "string"
            ? error
            : typeof error === "undefined" || !error.message
            ? "ra.auth.sign_in_error"
            : error.message,
          "warning"
        );
      });
  };

  const handleVerification = (values) => {
    setLoading(true);
    authProvider
      .verify(values)
      .then(() => {
        setLoading(false);
        history.push(redirectUrl);
      })
      .catch((error) => {
        setLoading(false);
        notify(
          typeof error === "string"
            ? error
            : typeof error === "undefined" || !error.message
            ? "ra.auth.sign_in_error"
            : error.message,
          "warning"
        );
      });
  };

  return (
    <Login {...pageProps}>
      {step === "login" ? (
        <LoginForm onSubmit={handleLogin} loading={loading} />
      ) : (
        <VerificationForm onSubmit={handleVerification} loading={loading} />
      )}
    </Login>
  );
};

export default LiveExpertsLogin;
