import { Resource } from 'react-admin';
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
;
import ticketViews from './Main/index.js';

const ticketsRoutes = [
  <Resource
    name='ticket'
    icon={SupportAgentIcon}
    options={{ label: 'Tickets', isMenuParent: true }}
  />,
  <Resource {...ticketViews} />,
];
export default ticketsRoutes;
