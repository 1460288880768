import React from "react";
import { VideoInput } from "components/Video/VideoComponents";
import { CreateToolbar } from "components/Toolbar/CreateToolbar";
import { FileUploaderPlaceholder } from "components/FileUploaderPlaceholder";
import { 
  Create,
  SimpleForm,
  TextInput,
  required,
  FileInput,
  ImageField
} from "react-admin";

export const TrainingCreate = (props) => (
  <Create {...props} title="Create Training">
    <SimpleForm variant="standard" margin="normal" toolbar={<CreateToolbar />}>

      <TextInput source='title' label='Title' validate={required()} />
      <TextInput source='description' label='Description' validate={required()} multiline />

      <FileInput
            source="thumbnail_file"
            accept="image/*"
            label="Thumbnail"
            placeholder={<FileUploaderPlaceholder />}
            validate={required()}
            fullWidth
          >
            <ImageField source="src" />
        </FileInput>

      <FileInput
        source="video_file"
        accept="video/*"
        label="Video"
        validate={[required()]}
        placeholder={<FileUploaderPlaceholder />}
        fullWidth
      >
        <VideoInput source="src" />
      </FileInput>

    </SimpleForm>
  </Create>
  );

