import AccountTreeIcon from '@mui/icons-material/AccountTree';

import { CastTypesList } from './list';
import { CastTypesEdit } from './edit';
import { CastTypesShow } from './show';
import { CastTypeCreate } from './create';

export default {
  name: "cast_types",
  options: { label: "Cast Types", menuParent: "cast_type" },
  icon: AccountTreeIcon,
  list: CastTypesList,
  show: CastTypesShow,
  create: CastTypeCreate,
  edit: CastTypesEdit,
};
