import React from "react";
import { CreateToolbar } from "components/Toolbar/CreateToolbar";
import { validateUserPassword } from '../helpers/validations/main';
import { Create, SimpleForm, TextInput, SelectInput, ReferenceInput, required } from "react-admin";

export const UserCreate = (props) => (
  <Create {...props} title="Create User">
    <SimpleForm variant="standard" margin="normal" toolbar={<CreateToolbar />}>
      <TextInput source="therapist_name" label="Therapist Name" validate={required()} fullWidth />
      <TextInput source="email" label="Email" validate={required()} fullWidth />
      <TextInput source="password" validate={[required(), validateUserPassword]} label="Password" fullWidth />

      <ReferenceInput
        source="clinic_id"
        reference="clinics"
        label="Clinic"
        perPage={500}
        validate={required()}
      >
         <SelectInput optionText={(record) => `${record.name} (${record.id})`} />
      </ReferenceInput>

    </SimpleForm>
  </Create>
  );

