import React from "react";
import { DebouncedTextField } from "components/Fields";
import FilterContainer from "components/containers/Filter";
import { 
  SelectInput
} from 'react-admin';

const OrderFilter = (props) => (
  <FilterContainer {...props} variant="standard" margin="normal" disableDates>

    <DebouncedTextField label="Full Name" source="full_name" />
    <DebouncedTextField label="Therapist Name" source="therapist_name" />

    <SelectInput
      source="order_status"
      label="Order Status"
      choices={[
        { id: "initialDraft", name: "Initial Draft" },
        { id: "submitted", name: "Submitted" },
        { id: "inProduction", name: "In Production" },
        { id: "readyForDispatch", name: "Ready for Dispatch" },
        { id: "enRoute", name: "En Route" },
        { id: "delivered", name: "Delivered" },
        { id: "cancelled", name: "Cancelled" },
      ]}
    />

  </FilterContainer>
);

export default OrderFilter;
