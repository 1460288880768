import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import { ViewTitle } from "../../../components/AppBar/Titles";
import { Show, SimpleShowLayout, TextField, ReferenceField, FunctionField } from "react-admin";

export const UsersShow = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent}/>
      <Show title={<ViewTitle source="name" />} {...props}>
        <SimpleShowLayout>
          <TextField source="sub" label="Sub" fullWidth />

          <ReferenceField 
            source="clinic_id"
            reference="clinics"
            label="Clinic"
            link={(record, reference) =>
              `/${reference}/${record.clinic_id}/show`
            }
          >
            <FunctionField render={(record) => `${record.name}`} />
          </ReferenceField>

          <TextField source="therapist_name" label="Therapist Name" fullWidth />
          <TextField source="email" label="Email" fullWidth />
        </SimpleShowLayout>
      </Show>
  </>
);
}