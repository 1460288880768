import React from "react";
import { EditTitle } from "../../../components/AppBar/Titles";
import { EditToolbar } from "../../../components/Toolbar/EditToolbar";
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  BooleanInput,
  ReferenceArrayInput,
  AutocompleteArrayInput
} from "react-admin";
import Breadcrumbs from "components/Breadcrumbs";

export const FractureLocationsEdit = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <Edit title={<EditTitle source="name" />} {...props}>
      <SimpleForm variant="standard" margin="normal" toolbar={<EditToolbar hidedelete />}>
        <TextInput source="location" label="Location" validate={required()} fullWidth />
        <BooleanInput source="is_available" label="Is Available?" validate={required()} fullWidth />

        <ReferenceArrayInput
            label="Cast Types"
            source="types_ids"
            reference="cast_types"
            perPage={500}
            filterToQuery={(searchText) => ({
              id: searchText,
              name: searchText,
              _autocomplete: true,
            })}
            sort={{ field: "id", order: "ASC" }}
            fullWidth
            allowEmpty
          >
            <AutocompleteArrayInput
              matchSuggestion={(filterValue, suggestion) => true}
              optionText={(choice) =>
                choice && choice.id
                  ? `${choice.type} (${choice.id})`
                  : "None"
              }
            />
        </ReferenceArrayInput>

      </SimpleForm>
    </Edit>
    </>
  );
}