import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import { makeStyles } from '@material-ui/core/styles';
import { ViewTitle } from "../../../components/AppBar/Titles";
import { 
  Show,
  Tab,
  Datagrid,
  TextField,
  ImageField,
  BooleanField,
  ReferenceField,
  TabbedShowLayout,
  ReferenceArrayField,
} from "react-admin";

const useStyles = makeStyles({
  customImage: {
    '& img': {
      width: '200px',
      height: '200px',
    }
  },
});

export const CastColorsShow = (props) => {
  const classes = useStyles();
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent}/>
      <Show title={<ViewTitle source="name" />} {...props}>
        <TabbedShowLayout>

          <Tab label="Details">
            <TextField source='id' label='ID' />
            <TextField source='name' label='Name' />
            <BooleanField source='is_available' label='Is Available?' />
            <ImageField source="thumbnail.url" label="Thumbnail" className={classes.customImage} sortable={false} />

          </Tab>

          <Tab label="Cast Patterns">
            <ReferenceArrayField source="patterns_ids" reference="cast_patterns" fullWidth>

              <Datagrid>

                <ReferenceField 
                  source="id"
                  reference="cast_patterns"
                  label="Pattern"
                  link={(record, reference) =>
                    `/${reference}/${record.id}/show`
                  }
                >
                  <TextField source="name" label="Name" />
                </ReferenceField>
                <BooleanField source='is_available' label="Is Available?" />

              </Datagrid>

            </ReferenceArrayField>
          </Tab>

        </TabbedShowLayout>
      </Show>
  </>
);
}