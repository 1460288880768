import React from "react";
import { EditTitle } from "../../../components/AppBar/Titles";
import { EditToolbar } from "../../../components/Toolbar/EditToolbar";
import { FileUploaderPlaceholder } from "components/FileUploaderPlaceholder";
import {
  Edit,
  required,
  FileInput,
  TextInput,
  ImageField,
  SimpleForm,
  BooleanInput,
  ReferenceArrayInput,
  AutocompleteArrayInput
} from "react-admin";
import Breadcrumbs from "components/Breadcrumbs";

export const CastPatternsEdit = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <Edit title={<EditTitle source="name" />} {...props}>
      <SimpleForm variant="standard" margin="normal" toolbar={<EditToolbar hidedelete />}>
        <TextInput source='name' label='Name' />
        <BooleanInput source='is_available' label='Is Available?' />

        <ReferenceArrayInput
          label="Cast Types"
          source="types_ids"
          reference="cast_types"
          perPage={500}
          filterToQuery={(searchText) => ({
            id: searchText,
            name: searchText,
            _autocomplete: true,
          })}
          sort={{ field: "id", order: "ASC" }}
          validate={required()}
          fullWidth
          allowEmpty
        >
          <AutocompleteArrayInput
            matchSuggestion={(filterValue, suggestion) => true}
            optionText={(choice) =>
              choice && choice.id
                ? `${choice.name} (${choice.id})`
                : "None"
            }
          />
        </ReferenceArrayInput>

        <ReferenceArrayInput
          label="Cast Colors"
          source="colors_ids"
          reference="cast_colors"
          perPage={500}
          filterToQuery={(searchText) => ({
            id: searchText,
            name: searchText,
            _autocomplete: true,
          })}
          sort={{ field: "id", order: "ASC" }}
          fullWidth
          allowEmpty
        >
          <AutocompleteArrayInput
            matchSuggestion={(filterValue, suggestion) => true}
            optionText={(choice) =>
              choice && choice.id
                ? `${choice.name} (${choice.id})`
                : "None"
            }
          />
        </ReferenceArrayInput>

        <FileInput
            source="thumbnail_file"
            accept="image/*"
            label="Thumbnail"
            validate={required()}
            placeholder={<FileUploaderPlaceholder />}
            fullWidth
          >
            <ImageField source="src" />
        </FileInput>

      </SimpleForm>
    </Edit>
    </>
  );
}