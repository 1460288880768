import React from "react";
import { ConditionalField } from './components';
import Breadcrumbs from "components/Breadcrumbs";
import { ViewTitle } from "../../../components/AppBar/Titles";
import { 
  Show,
  TextField,
  SimpleShowLayout,
  FunctionField
} from "react-admin";

export const SettingsShow = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
      <Breadcrumbs parent={menuParent}/>
      <Show title={<ViewTitle source="name" />} {...props}>
        <SimpleShowLayout>

          <TextField source="key" value="Key" />
          <FunctionField
            label="Value"
            render={_ => <ConditionalField />}
        />
          <TextField source="description" value="Description" />
          
        </SimpleShowLayout>
      </Show>
  </>
);
}