import React from 'react';
import TrainingFilter from './filter';
import Breadcrumbs from 'components/Breadcrumbs';
import DataGridList from 'components/containers/List';
import { makeStyles } from '@material-ui/core/styles';
import EmptyPage from 'components/Page/Empty/EmptyPage';
import { Paginator } from 'components/Paginator/Paginator';
import ListActions from 'components/ListAction/ListActions';
import { VideoField } from 'components/Video/VideoComponents';

import { 
  List,
  TextField, 
  ImageField
} from 'react-admin';

const useStyles = makeStyles({
  customImage: {
    '& img': {
      width: '125px',
      height: '125px',
    }
  },
});

export const TrainingsList = (props) => {
  const classes = useStyles();
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <List
      title='Trainings'
      filters={<TrainingFilter />}
      pagination={<Paginator />}
      perPage={15}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
      empty={<EmptyPage create />}
      actions={<ListActions listExporterType='Trainings' hideCreateButton={false} />}
    >
      <DataGridList edit show>

        <TextField source='title' label='Title' />
        <TextField source='description' label='Description' />
        <ImageField source="thumbnail.url" label="Thumbnail" sortable={false} className={classes.customImage} />
        <VideoField source="video.url" label="Video" />

      </DataGridList>
      
    </List>
    </>
    )
};