import { Resource } from 'react-admin';
import SettingsIcon from '@mui/icons-material/Settings';

import settingsViews from './Main/index.js';

const settingsRoutes = [
  <Resource
    name='_settings'
    icon={SettingsIcon}
    options={{ label: 'Trainings', isMenuParent: true }}
  />,
  <Resource {...settingsViews} />,
];
export default settingsRoutes;
