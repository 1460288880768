import { makeStyles } from '@material-ui/core/styles';
import { useRecordContext, Labeled } from 'react-admin';

const useStyles = makeStyles({
  scrollBox: {
    maxHeight: '200px',  // Maximum height of the box
    overflowY: 'auto',   // Enable vertical scrolling
    overflowX: 'hidden', // Prevent horizontal scrolling
    padding: '8px',      // Padding inside the box
    border: '1px solid lightgray', // Border around the box
    borderRadius: '4px', // Rounded corners
    backgroundColor: 'transparent', // Transparent background
    whiteSpace: 'pre-wrap', // Wrap text to the next line
    wordBreak: 'break-word' // Ensure long words do not cause horizontal scroll
  }
});

export const ShowBoxTextField = ({ source, label }) => {
  const record = useRecordContext();
  const classes = useStyles();
  return (
    <Labeled label={label} source={source}>
      <div className={classes.scrollBox}>
        {record[source]}
      </div>
    </Labeled>
  );
};

export const BoxTextField = ({ source, label }) => {
  const record = useRecordContext();
  const classes = useStyles();
  return (
      <div className={classes.scrollBox}>
        {record[source]}
      </div>
  );
};
