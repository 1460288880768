import React from 'react';
import UserFilter from './filter';
import Breadcrumbs from 'components/Breadcrumbs';
import DataGridList from 'components/containers/List';
import EmptyPage from 'components/Page/Empty/EmptyPage';
import { Paginator } from 'components/Paginator/Paginator';
import ListActions from 'components/ListAction/ListActions';
import { List, TextField, ReferenceField, FunctionField } from 'react-admin';

export const UsersList = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <List
      title='Users'
      filters={<UserFilter />}
      pagination={<Paginator />}
      perPage={15}
      sort={{ field: 'id', order: 'DESC' }}
      {...props}
      empty={<EmptyPage create />}
      actions={<ListActions listExporterType='Users' />}
    >
      <DataGridList edit show >
        <TextField source='therapist_name' label="Therapist Name" />
        <TextField source='email' label="Email" />

        <ReferenceField 
          source="clinic_id"
          reference="clinics"
          label="Clinic"
          link={(record, reference) =>
            `/${reference}/${record.clinic_id}/show`
          }
        >
          <FunctionField render={(record) => `${record.name}`} />
        </ReferenceField>


      </DataGridList>
      
    </List>
    </>
    )
};
