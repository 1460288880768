import { Resource } from 'react-admin';
import PatternIcon from '@mui/icons-material/Pattern';

import castPatternViews from './Main/index.js';

const castPatternsRoutes = [
  <Resource
    name='cast_pattern'
    icon={PatternIcon}
    options={{ label: 'Cast Patterns', isMenuParent: true }}
  />,
  <Resource {...castPatternViews} />,
];
export default castPatternsRoutes;
