import React from "react";
import { SelectInput } from 'react-admin';
import { DebouncedTextField } from "components/Fields";
import FilterContainer from "components/containers/Filter";

const TicketFilter = (props) => (
  <FilterContainer {...props} variant="standard" margin="normal" disableDates>
      <SelectInput
          label="Status"
          source="status"
          choices={[
            { id: 'open', name: "Open" },
            { id: 'resolved', name: "Resolved" },
          ]}
      />
  </FilterContainer>
);

export default TicketFilter;
