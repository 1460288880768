import { Resource } from 'react-admin';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';

import frequentlyAskedQuestionViews from './Main/index.js';

const frequentlyAskedQuestionsRoutes = [
  <Resource
    name='frequentlyAskedQuestion'
    icon={LiveHelpIcon}
    options={{ label: 'Frequently Asked Questions', isMenuParent: true }}
  />,
  <Resource {...frequentlyAskedQuestionViews} />,
];
export default frequentlyAskedQuestionsRoutes;
