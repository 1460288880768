import React from 'react';
import { ConditionalInput } from './components';
import Breadcrumbs from 'components/Breadcrumbs';
import { EditTitle } from '../../../components/AppBar/Titles';
import { EditToolbar } from '../../../components/Toolbar/EditToolbar';
import {
  Edit,
  SimpleForm,
  TextInput,
} from 'react-admin';

export const SettingsEdit = (props) => {
  const menuParent = props.options.menuParent;

  return (
    <>
    <Breadcrumbs parent={menuParent}/>
    <Edit title={<EditTitle source='name' />} {...props}>
      <SimpleForm variant='standard' margin='normal' toolbar={<EditToolbar />}>

        <TextInput source='key' label='Key' disabled fullWidth />
        <ConditionalInput />

        <TextInput source='description' label='Description' multiline />

      </SimpleForm>
    </Edit>
    </>
  );
}